import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  inject,
  PLATFORM_ID,
} from '@angular/core';
import {
  FirebaseApp,
  initializeApp,
  provideFirebaseApp,
  initializeServerApp,
} from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { provideNativeDateAdapter } from '@angular/material/core';
import {
  provideClientHydration,
  withEventReplay,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import {
  QueryClient,
  provideAngularQuery,
} from '@tanstack/angular-query-experimental';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AuthInterceptor, AuthService } from '@trueleap/auth';
import {
  BASE_API_URL,
  HOST_URL,
  REQUEST,
  VAPID_KEY,
} from '@trueleap/ui-tokens';
import { QuillModule } from 'ngx-quill';
import { provideNgxStripe } from 'ngx-stripe';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { getSubdomain } from './util';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { lastValueFrom } from 'rxjs';
import {
  provideLottieOptions,
  provideCacheableAnimationLoader,
} from 'ngx-lottie';
import { provideLoadingBar } from '@ngx-loading-bar/core';
import { provideLoadingBarRouter } from '@ngx-loading-bar/router';

(async () => {
  if (globalThis.navigator && 'serviceWorker' in navigator) {
    const installed = navigator.serviceWorker.controller;

    if (!installed) {
      console.log('sw not installed');
      const start = Date.now();
      await navigator.serviceWorker.register('./auth-service-worker.js', {
        scope: '/',
      });

      if (Date.now() - start < 10) {
        location.reload();
      }
    } else {
      navigator.serviceWorker
        .getRegistration('./auth-service-worker.js')
        .then(registration => {
          if (registration) {
            registration.update();
          }
        });
    }
  }
})();

export const appConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(
      withEventReplay(),
      withHttpTransferCacheOptions({
        includeRequestsWithAuthHeaders: true,
      })
    ),
    provideRouter(
      appRoutes,
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        defaultQueryParamsHandling: 'merge',
      })
    ),
    provideAnimations(),
    provideHttpClient(withFetch(), withInterceptors([AuthInterceptor])),
    provideFirebaseApp(() => {
      const platform = inject(PLATFORM_ID);
      const isBrowser = isPlatformBrowser(platform);
      if (isBrowser) {
        return initializeApp(environment.firebase);
      }
      const request = inject(REQUEST, { optional: true });
      if (!request) {
        return initializeApp(environment.firebase);
      }
      const authIdToken =
        request?.headers['authorization']?.split('Bearer ')[1];
      const app = initializeServerApp(environment.firebase, {
        authIdToken,
        releaseOnDeref: request || undefined,
      });
      return app;
    }),
    importProvidersFrom(QuillModule.forRoot()),
    provideStorage(injector =>
      getStorage(injector.get(FirebaseApp), 'gs://trueleap-plus-dev')
    ),
    provideFirestore(injector => getFirestore(injector.get(FirebaseApp))),
    provideMessaging(injector => getMessaging(injector.get(FirebaseApp))),
    provideNativeDateAdapter(),
    provideNgxStripe(
      `pk_test_51OZb5dJ9y6udfrZfch97Sh3KLw9kpVz0vKyPOsabVhg8XdheezZGtAK9h8ou1Olh9Szob1Ba8mllCC1T5rmadoZa00XAfksICi`
    ),
    provideAuth(() => getAuth(inject(FirebaseApp))),
    {
      provide: BASE_API_URL,
      useValue: environment.api.BASE_URL,
    },
    {
      provide: HOST_URL,
      useValue: environment.host,
    },
    provideAngularQuery(new QueryClient()),
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AuthService, DOCUMENT, PLATFORM_ID],
      useFactory: (
        authService: AuthService,
        document: Document,
        platformId: object
      ) => {
        return async () => {
          const isBrowser = isPlatformBrowser(platformId);
          if (isBrowser) {
            const subdomainName =
              getSubdomain(document.location.hostname || '') || '';
            authService.setSubDomain(subdomainName);
          }
          try {
            if (isBrowser) {
              const searchParams = new URLSearchParams(window.location.search);
              const token = searchParams.get('token');
              if (token) {
                await authService.loginWithToken(token);
              }
              searchParams.delete('token');
              if (token && history.replaceState) {
                const searchString =
                  searchParams.toString().length > 0
                    ? '?' + searchParams.toString()
                    : '';
                const newUrl =
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  searchString +
                  window.location.hash;
                history.replaceState(null, '', newUrl);
              }
            }
            await lastValueFrom(authService.getSignedInUser());
          } catch (err) {
            const error = err as Error;
            console.log(`Error while authenticating user: ${error.message}`);
          }

          return Promise.resolve(true);
        };
      },
    },
    {
      provide: VAPID_KEY,
      useValue: environment.vapIdKey,
    },
    provideLottieOptions({
      player: () => import('lottie-web/build/player/lottie_svg'),
    }),
    provideCacheableAnimationLoader(),
    provideLoadingBar({}),
    provideLoadingBarRouter(),
  ],
};
